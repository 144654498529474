.InnerTopContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    gap: 70px;
    flex-wrap: wrap;
}

.Container {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    /* margin: auto; */
    gap: 40px;
    width: 260px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    /* background-color: #7cf9d55a; */
    /* background-color: #d1e561a1; */
}

.ButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Button {
    /* background-color: aquamarine; */
    /* background-color: #c6df3d; */
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
}

.ButtonText {
    font-size: 12px;
    font-weight: 600;
    color: rgb(91, 89, 89);
}

.pieContainer {
    width: 220px;
    margin: auto;
}

.InnerContainerText {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 10px;
    color: rgb(91, 89, 89);
}

.InnerContainerTextStudent {
    /* border: 10px solid aquamarine; */
    /* border: 10px solid #7cf9d6; */
    padding: 10px;
    border-radius: 100%;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: rgb(91, 89, 89);
}