.ChangePassMainDiv {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    /* gap: 80px; */
}

.ChangePassDiv {
    height: auto;
    width: 95%;
    margin: auto;
    max-width: 500px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    background-color: #cdeaf3;
    border-radius: 10px;
    /* justify-content: center; */
}

.OldPassContainer {
    width: 80%;
    padding: 11px 0px;
    display: flex;
    align-items: center;
    align-self: center;
    flex-direction: row;
    margin-top: 20px;
    /* border: 1px solid black; */
    background-color: white;
}

.icon {
    font-size: 15px;
}

.ForgotPassText {
    font-size: 11px;
    /* margin-bottom: 2px; */
    padding-left: 60px;
    margin-top: 4px;
}

.InputFieldContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.inputBox {
    padding-left: 10px;
    outline: none;
    border: none;
    width: 100%;
}

.lockunlockIcon {
    border: none;
    margin-right: 10px;
    font-size: 18px;
}

.ResetPasswordText {
    display: flex;
    justify-content: center;
    margin: 30px 0px;
    font-weight: 700;
    font-size: 20px;
}

.ChangepassImage {
    height: 300px;
    width: 320px;
}

.ButtonContainer {
    width: 50%;
    padding: 8px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-top: 40px;
    background-color: #aad7e5;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 60px;
}