/* .react-datepicker-wrapper,
.react-datepicker__input-container, */
.react-datepicker__input-container input {
    all: unset;
    padding: 4px 4px;
    font-weight: 400;
    font-size: 16px;
    width: 90%;
    border: 1px solid gray;
    background-color: white;
    color: black;
}