.SideBar {
    /* background-color: #111827; */
    background-color: #ffd1dce0;
    color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: auto !important;
}

.logout,
.logo {
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
    align-items: center;
    color: #000000c8;
}

.routes {
    /* background-color: #1f2937; */
    /* background-color: #fee7cc; */
    height: 100%;
    border-radius: 10px 10px 0px 0px;
    padding-top: 10px;
    overflow: auto;
}

.routes::-webkit-scrollbar {
    display: none;
}

.routes {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.logout {
    padding: 10px 20px;
}

a {
    text-decoration: none;
}

.Link,
.menu {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    color: #fff;
    gap: 10px;
    transition: 0.2s cubic-bezier(0.5, -0.28, 0.735, 0.045);
    cursor: pointer;
    border-right: 3px transparent;
}

.link_Text {
    font-size: 20px;
    color: #000000c8;
}

.Link:hover,
.active,
.menu:hover {
    border-right: 3px solid #fff;
    background-color: #FFC1CC;
    border-radius: 5px 0px 0px 5px;
    transition: 0.2s cubic-bezier(0.5, -0.28, 0.735, 0.045);
}

.menu {
    justify-content: space-between;
}

.menu-item {
    display: flex;
    gap: 10px;
}

.subrote {
    display: flex;
    flex-direction: column;
}

.subrote .Link {
    padding-left: 40px;
    /* border-bottom: 1px solid white; */
}

.navActive {
    border: none;
    background-color: #dbdbdb;
    border-radius: 5px;
    padding: 5px 20px;
    transition: none;
    width: min-content;
    font-weight: 700;
    font-size: 25px;
    color: #111827;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.navActive:hover {
    color: #1f2937;
    background-color: #ededed;
}